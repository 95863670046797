import React from 'react'
import CrystalSandBanner from '../assets/Videos/CRYSTAL SAND (5.74 MB).mp4'
import '../css/crystal.css';
import '../css/MediaQueries/MediaQueryCrystalSand.css';
import { Container } from 'react-bootstrap';
import { Table } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
export default function CrystalSandPage () {
  return (
    <div className='crystal-sand'>
      <Helmet>
        <title>
          Best aggregates and Crystal sand Manufacturer | Endula Infra
        </title>
        <meta
          name='keywords'
          content='construction sand,p sand manufacturers,aggregates and sand,best sand for construction,construction materials sand,sand for building construction.'
        />
        <meta
          name='description'
          content='Endula Infra is your go-to manufacturer for superior aggregates and crystal sand, offering the finest materials to bring purity and excellence to every build.'
        />
      </Helmet>
      <div className='fac-hero'>
        <div className='heading-wrap fac'>
          <h1
            className='hero-heading'
            style={{ opacity: 1 }}
            words-slide-up=''
            text-split=''
          >
            The Future of Sand is here           
          </h1>
        </div>
        <div
          className='arrows-trigger fac'
          style={{
            transform:
              'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
            transformStyle: 'preserve-3d'
          }}
        ></div>
        <div className='background-noise-new'></div>
        <div className='hero-gradient'></div>
        <div className='video-fac w-background-video w-background-video-atom flex justify-center items-center'>
          <video autoPlay loop muted playsInline style={{
            width:"100%" , height:"100%" ,objectFit:"cover"
          }}>
            <source src={CrystalSandBanner} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className='section-anim-trigger'></div>
      </div>
      <section className='section-spacer fac-video'></section>
      <div className='section-wrap email'>
        <section className='section email about-section'>
          <div className='section-cont email d-flex justify-content-center align-items-center'>
            <h2 className='h3-heading research'>Crystal Sand</h2>
            <div className='email-text-wrap'>
              <div
                style={{
                  transform:
                    'translate3d(0px, 0vw, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  transformStyle: 'preserve-3d',
                  opacity: 1
                }}
                className='email-heading'
              >
                Until recently River sand was the only kind of sand for
                construction activities, since it was very economical and
                abundantly available. But these days its very difficult to get
                good quality sand at short distances from developing cities ,
                due to over utilization of resources in the recent years.
              </div>
              <div
                data-w-id='cb010bdd-2817-14a8-ded0-2e219e70f18c'
                style={{
                  transform:
                    'translate3d(0px, 0vw, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  transformStyle: 'preserve-3d',
                  opacity: 1
                }}
                className='email-sm-text-wrap'
              >
                <div className='text-20px email'>
                  The phenomenal growth in construction industry over the last
                  decade has contributed to a wide gap between the supply and
                  demand of natural sand. In the process a lot of damage also
                  has been done to eco-systems by dredging sand from river beds
                  leading to the depletion of water ground levels in the various
                  parts of the country. This situation created a great need for
                  manufactured sand as a replacement for river sand.
                </div>
                <div className='text-20px email last'>
                  Across the world, there is growing support for the use of
                  manufactured sand, especially in the production of concrete.
                  Structural Engineers and construction technologists highly
                  recommend manufactured sand for its perfect particle shape,
                  consistent gradation, and zero impurities. This sand also
                  eliminates other constraints of river sand, like availability
                  and price fluctuation. This is the future of sand as we know
                  it. Welcome to the Future.
                </div>
              </div>
            </div>
            <div className='sand-img-wrap'>
              <div className='sand-image'></div>
            </div>
          </div>
          <div className='section-anim-trigger'></div>
        </section>
      </div>
      <div className='section-spacer email'></div>
      <div className='section-wrap programs'>
        <section className='section program'>
          <div className='section-cont program'>
            <Container fluid>
              <div className='benefits-heading'>
                <h2>Benefits of Crystal Sand</h2>
              </div>
              <Table bordered responsive>
                <thead className='text-center'>
                  <tr>
                    <th>Crystal Sand</th>
                    <th>Natural Sand</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Crystal sand doesn’t contain impurities like silt and
                      silica etc. This sand doesn’t contain any organic
                      material, so strength of the structure remains the same.
                    </td>
                    <td>
                      This sand contains impurities like silt, silica etc.
                      Natural sand contains organic impurities like pieces of
                      wood, leaves, bones etc which after some time gets decayed
                      and weakens the strength of the structure.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Crystal sand is made from only one type of stone, hence
                      the binding strength between the particles is good.
                    </td>
                    <td>
                      It is made from different types of stones, so binding
                      strength varies.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Gives better compressive strength as compared to river
                      sand.
                    </td>
                    <td>
                      Gives low compressive strength as compared to crystal
                      sand.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      This sand has proper gradation of coarse and fine
                      aggregates, so voids are filled completely. This minimizes
                      cement consumption.
                    </td>
                    <td>
                      Natural sand which is available today doesn’t have fines
                      below 600 microns in proper gradation. So, voids in the
                      concrete are not filled properly and also increases cement
                      consumption.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      As the voids are filled properly, strength of the concrete
                      is achieved.
                    </td>
                    <td>
                      As the voids are not filled properly, strength of the
                      concrete is not achieved.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Crystal sand has constant fineness modules of aggregate,
                      so no necessity of the change in concrete mix design.
                    </td>
                    <td>
                      As every truck of natural sand has different fineness
                      modules, every time the mixture of concrete has to be
                      changed.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Can be produced in ample quantity, so it is cheaper as
                      compared to natural sand.
                    </td>
                    <td>Available in less quantity so it is costlier.</td>
                  </tr>
                  <tr>
                    <td>
                      This sand is best as per Vaastu-shastra, as it doesn’t
                      contain any pieces of Bones etc.
                    </td>
                    <td>
                      Because of sand dragging, river beds have become deep,
                      it’s harmful to nature.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      This sand is best as per Vaastu-shastra, as it doesn’t
                      contain any pieces of Bones etc.
                    </td>
                    <td>
                      This sand contains pieces of bones etc. So it is not
                      suitable as per Vaastu-Shastra.
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Container>
            <div className='parallax-anim-trigger'></div>
          </div>
        </section>
      </div>
      <section className='section-spacer programs'></section>
      <div className='section-wrap people'>
        <section className='section people'>
          <div className='section-cont people'>
            <div
              className='people-heading people'
              style={{
                transform:
                  'translate3d(0px, 0vw, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                transformStyle: 'preserve-3d',
                opacity: 1
              }}
            >
              Why Crystal Sand?
            </div>
            <div
              className='people-section-descr science people'
              style={{
                transform:
                  'translate3d(0px, 0vw, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                transformStyle: 'preserve-3d',
                opacity: 1
              }}
            >
              To accompany those interested in making an informed choice of
              study path, we offer individuals and schools the opportunity to
              get to know the university by participating in orientation people,
              such as the Open Day.
            </div>
          </div>
        </section>
      </div>
      <section className='section-spacer people'></section>
    </div>
  )
}
