import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';

const LogoAnimation = () => {
  const svgRef = useRef(null);
  const [isLogoVisible, setIsLogoVisible] = useState(true);

  useEffect(() => {
    // Animate logo on load
    const tl = gsap.timeline({ defaults: { duration: 0.5 } });

    tl.from(svgRef.current.querySelectorAll('.animate'), {
      opacity: 0,
      y: 50,
      stagger: 0.1,
    }).to(svgRef.current.querySelectorAll('.animate'), {
      opacity: 1,
      y: 0,
      duration: 0.5,
    });

    // Track scroll position
    const handleScroll = () => {
      const firstSectionHeight = window.innerHeight; // Assuming the first section takes the full viewport height
      const scrollTop = window.scrollY;

      // If scrolled past the first section, hide the logo
      if (scrollTop > firstSectionHeight) {
        setIsLogoVisible(false);
      } else {
        setIsLogoVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    isLogoVisible && (
    <svg
      ref={svgRef}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 841.89 595.28"
      width="auto" // Set desired width
      height="200" // Maintain aspect ratio
      xmlSpace="preserve"
      aria-label="Logo Animation"
      role="img"
    >
      <style>
        {`
          .st0 { fill: #107EA1; }
          .st1 { fill: #E26026; }
          .st2 { fill: #14385E; }
          .st3 { fill: url(#SVGID_1_); }
          .st4 { fill: #16A9C2; }
          .st5 { fill: #080909; }
          .st6 { fill: none; stroke: #080909; stroke-width: 2.161; stroke-miterlimit: 10; }
        `}
      </style>
      <g>
        <g>
          <path
            className="st0 animate"
            d="M214.72,358.71c69.44-89.1,141.49-184.99,211.78-273.45c38.48,49.8,92.64,120.49,130.65,170.43
              c20.36,26.76,62.19,82.33,82.77,109.44C504.55,364.55,349.85,360.79,214.72,358.71L214.72,358.71z M214.72,358.71
              c130.61-1.78,281.58-5.72,412.46-6.3c0,0-5.02,10.12-5.02,10.12l-50.35-65.9c-46.08-60.33-104.34-137.45-149.97-198.5
              c0,0,9.1,0.12,9.1,0.12c-17.48,21.58-35.18,42.97-52.92,64.42C326.19,225.32,266.36,295.85,214.72,358.71L214.72,358.71z"
          />
          <polygon
            className="st2 animate"
            points="420.93,346.19 404.75,337.27 396.75,332.76 388.56,328.26 380.56,323.84 372.38,319.43 
              364.38,314.92 354.65,309.49 354.44,291.09 363.92,296.53 372.1,300.94 380.19,305.36 388.19,309.86 396.38,314.37 404.38,318.78 
              412.26,323.16 412.29,314 412.2,304.81 404.01,300.3 396.01,295.79 379.83,286.97 379.83,286.87 371.73,282.37 354.44,272.58 
              354.54,254.35 363.27,259.47 371.37,263.98 387.55,272.9 395.64,277.4 412.26,286.46 412.26,268.18 371,245.58 362.9,241.08 
              354.54,236.5 354.44,218.18 420.95,254.5"
          />
          <linearGradient
            id="SVGID_1_"
            gradientUnits="userSpaceOnUse"
            x1="420.9466"
            y1="282.1751"
            x2="487.6428"
            y2="282.1751"
          >
            <stop offset="0" style={{ stopColor: "#079DC1" }} />
            <stop offset="0.9249" style={{ stopColor: "#1B3C6B" }} />
          </linearGradient>
          <polygon
            className="st3 animate"
            points="420.96,346.19 437.19,337.27 445.22,332.76 453.43,328.25 461.45,323.84 469.66,319.42 
              477.68,314.92 487.44,309.48 487.64,291.09 478.14,296.52 469.93,300.94 461.82,305.35 453.79,309.86 445.59,314.37 
              437.56,318.78 429.65,323.16 429.63,314 429.72,304.8 437.93,300.29 445.95,295.79 462.19,286.96 462.19,286.87 470.3,282.36 
              487.64,272.57 487.55,254.34 478.79,259.46 470.67,263.96 454.44,272.89 446.32,277.39 429.65,286.45 429.65,268.17 
              471.04,245.57 479.16,241.06 487.55,236.49 487.48,218.16 420.95,254.49"
          />
          <polygon
            className="st4 animate"
            points="487.48,218.19 429.64,249.82 428.35,250.56 420.99,254.51 354.41,218.19 361.86,214.14 
              420.81,181.58 433.41,188.48 374.46,220.76 387.33,227.57 446.1,195.1 458.7,202 399.93,234.19 417.04,243.2 474.88,211.29"
          />
        </g>
        
        <g>
		<g>
			<path className="st5" d="M336.9,499.96V479.3h3.44v20.65H336.9z"/>
			<path className="st5" d="M367.65,500.65l-13.42-13.93v13.24h-3.17v-21.34l13.42,13.91V479.3h3.17V500.65z"/>
			<path className="st5" d="M378.16,499.96V479.3h10.6v2.89h-7.3v3.85h6.61v2.89h-6.61v11.01H378.16z"/>
			<path className="st5" d="M397.42,499.96V479.3h5.76c1.39,0,2.59,0.28,3.59,0.84c1,0.56,1.77,1.3,2.31,2.21
				c0.54,0.92,0.8,1.92,0.8,3.01c0,1.1-0.28,2.1-0.83,2.98c-0.55,0.89-1.32,1.58-2.32,2.09l6.19,9.52h-3.79l-5.58-8.67h-2.85v8.67
				H397.42z M400.72,488.46h2.41c0.81,0,1.48-0.15,2-0.46c0.52-0.31,0.9-0.7,1.15-1.17c0.24-0.47,0.37-0.96,0.37-1.47
				c0-0.89-0.32-1.61-0.96-2.18s-1.49-0.85-2.55-0.85h-2.41V488.46z"/>
			<path className="st5" d="M418.69,499.96l8.93-21.36h0.05l8.93,21.36h-3.46l-1.97-4.91h-7.14l-2,4.91H418.69z M425.14,492.32h4.89
				l-1.26-3.1c-0.23-0.55-0.44-1.08-0.63-1.59c-0.19-0.51-0.38-1.12-0.56-1.82c-0.18,0.69-0.37,1.29-0.55,1.81s-0.39,1.05-0.62,1.61
				L425.14,492.32z"/>
			<path className="st5" d="M457.2,499.96V479.3h3.44v17.62h6.68v3.03H457.2z"/>
			<path className="st5" d="M476.39,499.96V479.3h3.44v17.62h6.68v3.03H476.39z"/>
			<path className="st5" d="M495.58,499.96V479.3h5.69c1.39,0,2.57,0.21,3.55,0.63c0.97,0.42,1.75,0.97,2.35,1.65
				c0.6,0.68,1.03,1.42,1.31,2.21c0.28,0.8,0.41,1.58,0.41,2.36c0,0.75-0.13,1.53-0.4,2.33c-0.27,0.8-0.7,1.54-1.3,2.23
				c-0.6,0.68-1.38,1.23-2.35,1.65c-0.97,0.42-2.16,0.63-3.57,0.63h-2.39v6.95H495.58z M498.88,490.04h2.2c1.1,0,1.98-0.2,2.63-0.59
				c0.65-0.39,1.11-0.88,1.39-1.47c0.28-0.59,0.41-1.2,0.41-1.82c0-0.61-0.13-1.22-0.4-1.81c-0.27-0.6-0.72-1.09-1.36-1.49
				c-0.64-0.4-1.53-0.6-2.66-0.6h-2.2V490.04z"/>
		</g>
	</g>
	<g>
		<g>
			<g>
				<g>
					<line className="st6" x1="209.51" y1="493.13" x2="323.59" y2="493.13"/>
					<g>
							<ellipse transform="matrix(0.5847 -0.8112 0.8112 0.5847 -312.9711 374.8898)" className="st5" cx="209.68" cy="493.13" rx="3.41" ry="3.41"/>
					</g>
				</g>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<line className="st6" x1="632.38" y1="493.13" x2="518.3" y2="493.13"/>
				<g>
					<path className="st5" d="M632.21,489.72c1.88,0,3.41,1.53,3.41,3.41c0,1.88-1.53,3.41-3.41,3.41c-1.88,0-3.41-1.53-3.41-3.41
						C628.8,491.25,630.33,489.72,632.21,489.72z"/>
				</g>
			</g>
		</g>
	</g>
	<g>
		<g>
			<path className="st5" d="M211.91,455.93c0.4-2.06,0.61-5.53,0.61-10.39v-48.18c0-4.94-0.2-8.42-0.61-10.44
				c-0.41-2.03-1.49-3.04-3.26-3.04v-1.11h59.13v11.05c-3.61,0-7.22-0.42-10.83-1.27c-3.61-0.85-7.96-2.12-13.04-3.81
				c-4.42-1.47-8.16-2.61-11.22-3.43c-3.06-0.81-6.1-1.29-9.12-1.44v37.02h33.15v1.66h-33.15v36.47c3.02-0.15,6.06-0.62,9.12-1.44
				c3.06-0.81,6.8-1.95,11.22-3.43c5.08-1.69,9.43-2.96,13.04-3.81c3.61-0.85,7.22-1.27,10.83-1.27v11.05h-59.13v-1.11
				C210.42,459.02,211.5,457.99,211.91,455.93z"/>
			<path className="st5" d="M279.93,385.97v59.57c0,4.86,0.2,8.33,0.61,10.39c0.4,2.06,1.49,3.09,3.26,3.09v1.11h-9.39v-1.11
				c1.77,0,2.86-1.03,3.26-3.09c0.4-2.06,0.61-5.53,0.61-10.39v-48.18c0-4.94-0.2-8.42-0.61-10.44c-0.41-2.03-1.49-3.04-3.26-3.04
				v-1.11h16.69l47.41,71.5v-56.92c0-4.94-0.2-8.42-0.61-10.44c-0.41-2.03-1.49-3.04-3.26-3.04v-1.11h9.39v1.11
				c-1.77,0-2.86,1.01-3.26,3.04c-0.41,2.03-0.61,5.51-0.61,10.44v48.18c0,4.86,0.2,8.33,0.61,10.39c0.4,2.06,1.49,3.09,3.26,3.09
				v1.11h-14.92L279.93,385.97z"/>
			<path className="st5" d="M353.92,455.93c0.4-2.06,0.61-5.53,0.61-10.39v-48.18c0-4.94-0.2-8.42-0.61-10.44
				c-0.41-2.03-1.49-3.04-3.26-3.04v-1.11h30.39c5.89,0.07,11.44,0.88,16.63,2.43c5.19,1.55,9.82,5.18,13.87,10.89
				c4.05,5.71,6.08,14.17,6.08,25.36v0.11c0,11.2-2.03,19.64-6.08,25.31c-4.05,5.67-8.67,9.28-13.87,10.83
				c-5.19,1.55-10.74,2.36-16.63,2.43h-30.39v-1.11C352.43,459.02,353.51,457.99,353.92,455.93z M379.39,458.47
				c5.01,0,9.43-0.79,13.26-2.38c3.83-1.58,7.11-5.08,9.84-10.5c2.72-5.42,4.09-13.46,4.09-24.15s-1.36-18.73-4.09-24.15
				c-2.73-5.42-6.01-8.91-9.84-10.5c-3.83-1.58-8.25-2.38-13.26-2.38h-13.81v74.05H379.39z"/>
			<path className="st5" d="M440.84,458.25c-4.49-1.99-7.81-5.16-9.95-9.5c-2.14-4.35-3.24-10.13-3.32-17.35v-34.04
				c0-4.94-0.2-8.42-0.61-10.44c-0.41-2.03-1.49-3.04-3.26-3.04v-1.11h18.79v1.11c-1.77,0-2.86,1.01-3.26,3.04
				c-0.41,2.03-0.61,5.51-0.61,10.44l-0.11,34.04c0.07,6.85,0.68,12.29,1.82,16.3c1.14,4.02,3.15,7,6.02,8.95
				c2.87,1.95,6.92,2.93,12.16,2.93c5.23,0,9.28-0.99,12.16-2.98c2.87-1.99,4.86-5.05,5.97-9.17c1.11-4.12,1.66-9.69,1.66-16.69
				v-33.38c0-4.94-0.2-8.42-0.61-10.44c-0.41-2.03-1.49-3.04-3.26-3.04v-1.11h18.79v1.11c-1.77,0-2.86,1.01-3.26,3.04
				c-0.41,2.03-0.61,5.51-0.61,10.44v33.38v0.66c-0.07,7.15-1.18,12.91-3.32,17.3c-2.14,4.38-5.43,7.57-9.89,9.56
				c-4.46,1.99-10.33,2.98-17.63,2.98C451.23,461.23,445.33,460.24,440.84,458.25z"/>
			<path className="st5" d="M499.85,459.02c1.77,0,2.86-1.03,3.26-3.09c0.4-2.06,0.61-5.53,0.61-10.39v-48.18
				c0-4.94-0.2-8.42-0.61-10.44c-0.41-2.03-1.49-3.04-3.26-3.04v-1.11h18.79v1.11c-1.77,0-2.86,1.01-3.26,3.04
				c-0.41,2.03-0.61,5.51-0.61,10.44v61.67c3.09-0.15,6.17-0.62,9.23-1.44c3.06-0.81,6.94-2.03,11.66-3.65
				c5.01-1.69,9.04-2.93,12.1-3.7c3.06-0.77,6.06-1.16,9.01-1.16v11.05h-56.92V459.02z"/>
			<path className="st5" d="M614.35,459.02c1.4,0,2.1-0.77,2.1-2.32c0-1.25-0.59-3.65-1.77-7.18l-3.54-9.73
				c-2.28,6.26-5.99,11.4-11.11,15.42c-5.12,4.02-11.37,6.02-18.73,6.02c-5.08,0-9.43-1.29-13.04-3.87
				c-3.61-2.58-5.42-6.92-5.42-13.04c0-4.94,1.6-10.44,4.81-16.52c3.2-6.08,7.11-11.25,11.71-15.53c4.6-4.27,8.86-6.41,12.77-6.41
				c3.09,0,5.56,0.81,7.4,2.43l-3.98-10.94c-1.77-4.79-3.24-8.23-4.42-10.33c-1.18-2.1-2.65-3.15-4.42-3.15v-1.11h15.25l22.99,62.77
				c1.77,4.79,3.24,8.23,4.42,10.33c1.18,2.1,2.65,3.15,4.42,3.15v1.11h-19.45V459.02z M587.6,456.26c5.45,0,10.18-1.73,14.2-5.19
				c4.01-3.46,6.83-7.99,8.45-13.59l-8.18-22.32c-0.88-2.43-2.08-4.22-3.59-5.36c-1.51-1.14-3.19-1.71-5.03-1.71
				c-3.39,0-6.59,1.71-9.61,5.14c-3.02,3.43-5.45,7.81-7.29,13.15c-1.84,5.34-2.76,10.7-2.76,16.08
				C573.79,451.66,578.39,456.26,587.6,456.26z"/>
		</g>
	</g>
      </g>
    </svg>
    )
  );
};

export default LogoAnimation;
