import React, { useEffect } from 'react'
import '../css/aggregates.css';
import '../css/MediaQueries/MediaQueryAggregates.css';
import Lottie from 'lottie-react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import AggregatesAnimationData from '../Lottiefiles/about page 2.json'
import { Container, Row, Col } from 'react-bootstrap'
import AggregatesVideo from '../assets/Videos/AGGREGATES (4.57 MB).mp4'
import { Helmet } from 'react-helmet'
export default function AgrregatesPage () {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Customize the animation duration
      once: false, // Ensure the animation occurs every time the element comes into view
      offset: 120 // Start the animation a bit earlier
    })
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='Aggregates'>
      <Helmet>
        <title>
          Best aggregates construction material supplier in Hyderabad
        </title>
        <meta
          name='keywords'
          content='aggregate construction material,aggregate supplier,coarse aggregate,best aggregate for concrete,coarse aggregate and fine aggregate,20mm aggregate.'
        />
        <meta
          name='description'
          content='Endula has been the best aggregate construction material supplier in hyderabad offering 20mm aggregates, coarse and fine aggreegates for all civil projects.'
        />
      </Helmet>
      <div className='fac-hero'>
        <div className='heading-wrap fac'>
          <h1
            words-slide-up=''
            text-split=''
            data-w-id='9e05ae56-550b-8e01-1f0d-a0f7876aa9b0'
            className='hero-heading academ-staff'
            style={{ opacity: 1 }}
          >
            Strength in every construction
          </h1>
        </div>
        <div
          data-w-id='c861e680-6a80-9832-3d3e-6dc0eca48127'
          className='arrows-trigger fac'
          style={{
            transform:
              'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
            transformStyle: 'preserve-3d'
          }}
        ></div>
        <div className='background-noise-new'></div>
        <div className='hero-gradient'></div>
        <div className='hero-img staff'></div>
        <div className='section-anim-trigger'></div>
      </div>
      <section className='section-spacer fac-video'></section>
      <div className='section-wrap staff'>
        <section className='section staff'>
          <div className='section-cont staff'>
            <div
              style={{
                transform:
                  'translate3d(0px, 0vw, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                opacity: 1,
                transformStyle: 'preserve-3d'
              }}
              className='email-heading staff'
            >
              Endula Infra has been at the forefront of the construction
              materials industry, specializing in the production of high-quality
              aggregates.
            </div>
            <div className='d-flex justify-center align-items-center aggr-tab lg:mt-32'>
              <div className='email-lottie'>
                <Lottie
                  animationData={AggregatesAnimationData}
                  loop={true}
                  width='100%'
                  height='100%'
                />
              </div>
              <div
                style={{
                  transform:
                    'translate3d(0px, 0vw, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  opacity: 1,
                  transformStyle: 'preserve-3d'
                }}
                className='section-descr staff'
              >
                Starting with a manufacturing unit in Hyderabad, we have
                expanded our operations in [ branch places ] to meet the growing
                demands of the industry. Our commitment to superior standards
                and competitive pricing has made us a trusted name in aggregates
                production. Our leading edge facility in Hyderabad is equipped
                with advanced machinery and technology to produce a wide range
                of aggregates that meet the highest industry standards.
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className='section-spacer staff'></section>
      <div className='section-wrap email'>
        <section className='section email fac3'>
          <div className='section-cont email fac3'>
            <div className='benefits-heading'>
              <h2>Advantages of Aggregates</h2>
            </div>
            <div className='d-flex'>
              <Row>
                <Col lg={6}>
                  <div className='benefits-img-wrap'>
                    {/* <div className="benefits-img"></div> */}
                    <video autoPlay loop muted playsInline>
                      <source src={AggregatesVideo} />
                    </video>
                  </div>
                </Col>
                <Col lg={6}>
                  <div
                    className='fairs-row'
                    data-aos='fade-left'
                    data-aos-delay='200'
                  >
                    <div className='fairs-bullet'></div>
                    <div>
                      <h6>Superior Load-Bearing Capacity</h6>
                      <p>
                        Our metal aggregates, particularly the 20mm and 40mm
                        sizes, offer excellent load-bearing capacity, making
                        them ideal for heavy-duty construction work.
                      </p>
                    </div>
                  </div>
                  <div
                    className='fairs-row'
                    data-aos='fade-left'
                    data-aos-delay='400'
                  >
                    <div className='fairs-bullet'></div>
                    <div>
                      <h6>Excellent Binding Properties</h6>
                      <p>
                        Aggregates like our 10mm metal provide superior binding,
                        ensuring strong adhesion in concrete mixes and enhanced
                        structural integrity.
                      </p>
                    </div>
                  </div>
                  <div
                    className='fairs-row'
                    data-aos='fade-left'
                    data-aos-delay='600'
                  >
                    <div className='fairs-bullet'></div>
                    <div>
                      <h6>Versatility</h6>
                      <p>
                        From fine plaster sand to robust 40mm metal, our range
                        of products caters to various construction needs, making
                        Endula Infra a one-stop solution for all aggregate
                        requirements.
                      </p>
                    </div>
                  </div>
                  <div
                    className='fairs-row'
                    data-aos='fade-left'
                    data-aos-delay='800'
                  >
                    <div className='fairs-bullet'></div>
                    <div>
                      <h6>Environmental Responsibility</h6>
                      <p>
                        Our crushed rock fine is an environmentally friendly
                        alternative to natural sand, promoting sustainable
                        construction practices.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </section>
      </div>
      <div className='section-spacer email'></div>
    </div>
  )
}
