import React from 'react';
import '../css/home.css';
import '../css/MediaQueries/MediaQueryHome.css';
import HomePage from './HomePage';
import { Helmet } from 'react-helmet';
export default function Home() {
  return (
    <div >
         <Helmet>
        <title>Building and Construction Material Supplier in Hyderabad</title>
        <meta name="keywords" content="building materials supplier,construction material supply,building material manufacturers,construction material manufacturers,building construction material supplier." />
        <meta name="description" content="Endula Infra is a leading construction material supplier delivering dust free aggregates, RMC and Crystal Sand for durable and sustainable infrastructures." />
      </Helmet>
        {/* <Header /> */}
        {/* <LandingPage /> */}
        <HomePage />
        {/* <Footer /> */}
    </div>
  )
}
