import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
export default function AboutProductCarousel() {
  const carouselRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const totalItems = 3;
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700); // Adjust the breakpoint as needed
    };

    handleResize(); // Check on initial render
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const move = (direction) => {
    if (buttonDisabled) return;
    setButtonDisabled(true);
    setTimeout(() => setButtonDisabled(false), 800);
    setCurrentIndex((prevIndex) =>
      direction === "next"
        ? (prevIndex + 1) % totalItems
        : (prevIndex - 1 + totalItems) % totalItems
    );
  };

  useEffect(() => {
    const translateValue = -currentIndex * `${isMobile ? "100" : "70"}`;
    carouselRef.current.style.transform = `translateX(${translateValue}%)`;
  }, [currentIndex]);

  return (
    <div className="about-carousel">
      <div className="carousel__wrapper">
        <div className="carousel__nav">
          <div
            className="carousel__nav__item"
            onClick={() => move("prev")}
            disabled={buttonDisabled}
          >
            <FontAwesomeIcon icon={faChevronLeft} size="lg" />
          </div>
          <div
            className="carousel__nav__item"
            onClick={() => move("next")}
            disabled={buttonDisabled}
          >
            <FontAwesomeIcon icon={faChevronRight} size="lg" />
          </div>
        </div>
        <div className="carousel" ref={carouselRef}>
          <div
            className="carousel__item"
            style={{
              minWidth: isMobile ? "82vw" : "50vw",
            }}
          >
            <div className="video-bg">
              <video autoPlay muted loop>
                <source
                  src="https://uploads-ssl.webflow.com/6422c9403da290e00565400d/642689f9a039a73c9920dd4e_new-transcode.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <Link to="/Ready-Mix-Concrete">
              {" "}
              <button className="know-more-btn">Know More</button>
            </Link>
            <div className="carousel__text">
              <h3>Ready Mix Concrete</h3>
              <p>
                We provide a wide range of mix-designs ranging from M10 to M100,
                unmatched in the city.
              </p>
            </div>
          </div>
          <div
            className="carousel__item"
            style={{
              minWidth: isMobile ? "82vw" : "50vw",
            }}
          >
            <div className="video-bg">
              <video autoPlay muted loop>
                <source
                  src="https://uploads-ssl.webflow.com/6422c9403da290e00565400d/642689f9a039a73c9920dd4e_new-transcode.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <Link to="/Crystal-Sand">
              <button className="know-more-btn">Know More</button>
            </Link>
            <div className="carousel__text">
              <h3>Crystal Sand</h3>
              <p>
                This is the future of sand as we know it. Welcome to the Future.
              </p>
            </div>
          </div>
          <div
            className="carousel__item"
            style={{
              minWidth: isMobile ? "82vw" : "50vw",
            }}
          >
            <div className="video-bg">
              <video autoPlay muted loop>
                <source
                  src="https://uploads-ssl.webflow.com/6422c9403da290e00565400d/642689f9a039a73c9920dd4e_new-transcode.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <Link to="/Aggregates">
              <button className="know-more-btn">Know More</button>
            </Link>
            <div className="carousel__text">
              <h3>Aggregates</h3>
              <p>
                Aggregates are used in construction and civil engineering,
                including sand, gravel, and crushed stone.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
