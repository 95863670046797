import React from "react";
import AboutBannerVideo from "../assets/Videos/AboutPageBannerVideo.mp4";
import Lottie from "lottie-react";
import AboutAnimationData from "../Lottiefiles/about page 21.json";
import AboutProductCarousel from "./AboutProductCarousel";
import { Col, Container, Row } from "react-bootstrap";
import "../css/about.css";
import "../css/MediaQueries/MediaQueryAbout.css";
import { Helmet } from "react-helmet";
import aboutUs from "../assets/Images/about us 0.2.jpg";
export default function AboutUs() {
  return (
    <div className="About">
      <Helmet>
        <title>
          Leading Crystal sand and Construction material manufacturers.
        </title>
        <meta
          name="keywords"
          content="building construction material supplier,construction material manufacturers,material supplier for construction,aggregate suppliers,ready mix concrete supply."
        />
        <meta
          name="description"
          content="Endula Infra is the largest manufacturer of high quality construction materials like Crystal sand and Ready Mix Concrete in various forms for diverse projects."
        />
      </Helmet>
      <div className="fac-hero">
        <div className="heading-wrap fac">
          <h1
            className="hero-heading"
            style={{ opacity: 1 }}
            words-slide-up=""
            text-split=""
          >
            Forging durability, shaping possibility
          </h1>
        </div>
        <div
          className="arrows-trigger fac"
          style={{
            transform:
              "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d",
          }}
        ></div>
        <div className="background-noise-new"></div>
        <div className="hero-gradient"></div>
        <div className="video-fac w-background-video w-background-video-atom">
          <video autoPlay loop muted playsInline>
            <source src={AboutBannerVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="section-anim-trigger"></div>
      </div>
      <section className="section-spacer fac-video"></section>
      <div className="section-wrap email">
        <section className="section email about-section">
          <div className="section-cont email ">
            <div className="email-lottie">
              <Lottie
                animationData={AboutAnimationData}
                loop={true}
                width="100%"
                height="100%"
              />
            </div>
            <div className="email-text-wrap">
              <div
                style={{
                  transform:
                    "translate3d(0px, 0vw, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d",
                  opacity: 1,
                }}
                className="email-heading"
              >
                Endula Infra is a Stone crushing industry that is based in
                Hyderabad and has been in existence for more than three decades.
              </div>
              <div
                data-w-id="cb010bdd-2817-14a8-ded0-2e219e70f18c"
                style={{
                  transform:
                    "translate3d(0px, 0vw, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d",
                  opacity: 1,
                }}
                className="email-sm-text-wrap"
              >
                <div className="text-20px email">
                  They include eight affiliated companies that supply a solid
                  and diverse product list involved in mining, quarrying,
                  processing, blasting, and drilling.
                </div>
                <div className="text-20px email last">
                  Endula Infra’s vision is to deliver dust-free materials and
                  value added supply solutions for aggregates and material mixes
                  to drive up the output and profitability of businesses in
                  construction and infrastructure segments.
                </div>
              </div>
            </div>
          </div>
          <div className="section-anim-trigger"></div>
        </section>
      </div>
      <div className="section-spacer email"></div>
      <div className="section-wrap programs">
        <section className="section program">
          <div className="section-cont program">
            <h2 className="h3-heading program">
              The leadership at Endula Infra is composed of skilled partners
            </h2>
            <div className="section-descr program">
              Together, the dynamic leadership team drives Endula Infra towards
              setting new standards in the industry, continuously endeavor for
              excellence and innovative solutions to meet the needs of
              construction and infrastructure sectors.
            </div>
            <AboutProductCarousel />
            <div className="parallax-anim-trigger"></div>
          </div>
        </section>
      </div>
      <section className="section-spacer programs"></section>
      <div className="section-wrap people">
        <section className="section people">
          <div className="section-cont people">
            <h2 className="h3-heading people">People</h2>
            <Container fluid>
              <Row className="justify-center align-items-center people-description">
                <Col lg={6}>
                  <div className="people-image">
                    <div className="people-img">
                      <img src={aboutUs} style={{
                        width:"100%" , height:"100%"
                      }} alt="About Us" />
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="people-descr ps-lg-5 mb-5">
                    <h3>
                      Founder : <span>E. Narasimha Reddy</span>
                    </h3>
                    <p>
                      is a pioneering legend behind Endula Infra LLP, who led
                      the foundational stone. Having an experience of over 25+
                      years in the Construction Aggregate business, now looking
                      forward to a friendly sand alternative - Crystal Sand.
                    </p>
                  </div>
                  <div className="people-descr ps-lg-5 mb-5">
                    <h3>
                      Founder : <span>I. Malla Reddy</span>
                    </h3>
                    <p>
                      is the founder and a legend behind Endula Infra LLP. Under
                      his supervision, the stone-crushing industry has been
                      thriving for over 25 years while bringing innovative
                      environmentally friendly products which is possible
                      because of his extensive experience and rigorous research
                      over these years.
                    </p>
                  </div>
                  <div className="people-descr ps-lg-5 mb-5">
                    <h3>
                      Managing Partner : <span>E. Dinesh Reddy</span>
                    </h3>
                    <p>
                      with a Master's degree in Construction Management from
                      Bradley University, USA has extensive experience in the
                      railway industry and handles the technical aspects of the
                      company ensuring top-notch quality and innovation.
                    </p>
                  </div>
                  <div className="people-descr ps-lg-5">
                    <h3>
                      Managing Partner : <span>I. Surendar Reddy</span>
                    </h3>
                    <p>The Vice President and Head of Marketing and Sales.</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </div>
      <section className="section-spacer people"></section>
      <div className="section-wrap fac-pic">
        <section className="section fac-pic">
          <div
            className="fac-img"
            style={{
              willChange: "transform",
              transform:
                "translate3d(0px, -4.89285%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
            }}
          ></div>
          <div className="section-anim-trigger footer"></div>
        </section>
      </div>
      <section className="section-spacer fac-pic"></section>
    </div>
  );
}
