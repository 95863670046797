import React, { useState, useEffect } from "react";
import "../css/home.css";
import backgroundVideo from "../assets/Videos/blue & peach 00.1.mp4";
import Logo from "../assets/Images/ENDULA LOGO-01.png";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXTwitter,
  faYoutube,
  faFacebook,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
export default function Footer() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
    setError(""); // Clear error message when typing
  };

  const validateEmail = (email) => {
    // Basic email regex pattern for validation
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }
    // Proceed with form submission if email is valid
    console.log("Form submitted with email:", email);
    setEmail("");
  };

  const [hasScrolled, setHasScrolled] = useState(false);
  const handleScroll = () => {
    setHasScrolled(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="z-50">
      <section class="section-wrap footer">
        <div class="section footer">
          <div class="video-wrap footer" id="footerVdo">
            <div
              data-poster-url="videos/new-poster-00001.jpg"
              data-video-urls="videos/new-transcode.mp4,videos/new-transcode.webm"
              data-autoplay="true"
              data-loop="true"
              data-wf-ignore="true"
              class="gradient-video footer w-background-video w-background-video-atom"
            >
              <video autoPlay loop muted playsInline className="-z-50">
                <source src={backgroundVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div class="background-noise-new flex justify-center w-full z-50">
              <div className="w-[95%] sm:w-[80%] md:w-[85%] md:mt-3 lg:mt-2 xl:mt-5">
                <div className="fotert-top flex justify-between 2xl:mt-5 ">
                  <div>
                    <img
                      src={Logo}
                      className="sm:mt-10 w-36 hidden sm:block"
                      alt="logo"
                    />
                  </div>
                  <div className="sm:mt-5 gap-3 md:mt-10 lg:mt-5 hidden sm:block">
                    <Link
                      className="text-white z-50 text-xl lg:text-3xl pr-8 text-decoration-none sm:pt-8"
                      to="/Crystal-Sand"
                      style={{
                        pointerEvents:"auto"
                      }}
                    >
                      /  <span className="AnimationTitle">Crystal Sand</span>
                    </Link>
                    <Link
                      className="text-white text-xl lg:text-3xl pr-8 text-decoration-none sm:pt-8"
                      to="/Aggregates"
                      style={{
                        pointerEvents:"auto"
                      }}
                    >
                      /  <span className="AnimationTitle">Aggregates</span>
                    </Link>
                    <Link
                      className="text-white text-xl lg:text-3xl pr-8 text-decoration-none sm:pt-8"
                      to="/Ready-Mix-Concrete"
                      style={{
                        pointerEvents:"auto"
                      }}
                    >
                      /  &nbsp;
                      <span className="AnimationTitle">RMC</span>
                    </Link>
                  </div>
                </div>
                <div className="footer-middle  flex sm:justify-between  flex-col-reverse md:flex-row mt-32 sm:mt-10">
                  <div className="md:w-[400px] lg:w-[400px] sm:w-[300px]  ">
                    <div className="Footer-mail-desc lg:text-4xl mt-20 sm:mt-3 leading-4 text-2xl AnimationTitle">
                      Keep up with the news
                    </div>
                    <Form className="email-form" onSubmit={handleSubmit}>
                      <div className="input-container relative z-50">
                        <input
                          required
                          type="email"
                          placeholder="Email Address"
                          className="email-input w-[90%] z-[999] lg:w-[500px] md:w-[300px]  text-white text-4xl placeholder-white bg-transparent border-b-2"
                          value={email}
                          style={{
                            pointerEvents:"auto",
                            fontSize:"18px" 
                          }}
                       
                          onChange={handleChange}
                        />
                        <button
                          className="top-0 absolute right-3 md:right-[1rem] lg:right-1  hover:right-0 hover:transition-all hover:duration-500"
                          onClick={handleSubmit}
                          type="submit"
                          style={{
                            pointerEvents:"auto",
                            zIndex:"999"
                          }}
                        >
                          <img
                            src="https://www.egn.university/images/slider-arrow.svg"
                            alt="arrow icon"
                          />
                        </button>
                      </div>
                      {error && <div className="error-message">{error}</div>}
                    </Form>
                  </div>
                  <div className="footer-center-right md:w-[50%] lg:w-[30%] w-[60%] sm:w-[40%] mt-10 md:mt-0 z-50  flex justify-between items-start">
                    <div className="footer-center-wrap flex gap-2 flex-col items-start lg:flex-col">
                      <div className="colomn-1 flex flex-col  sm:gap-0 items-start">
                        <a
                          className="text-white text-xl  AnimationTitle sm:pb-0  no-underline"
                          href="https://"
                        >
                          Privacy
                        </a>
                        <a
                          className="text-white text-xl py-2 sm:py-0 sm:pb-0 AnimationTitle  no-underline"
                          href="https://"
                        >
                          Cookie Policy
                        </a>
                        <a
                          className="text-white text-xl  sm:pb-0 AnimationTitle  no-underline"
                          href="https://"
                        >
                          Terms Of Use
                        </a>
                      </div>
                      <div className="colomn-2 flex  sm:gap-0  sm:mt-0 flex-col items-start">
                        <a
                          href="https://"
                          className="text-white text-xl p- sm:pb-0  no-underline"
                        >
                          9876543210
                        </a>
                        <a
                          href="https://"
                          className="text-white text-xl pb-1 sm:pb-0 py-2 no-underline"
                        >
                          info@endula.com
                        </a>
                      </div>
                    </div>
                    <div className="flex flex-row gap-4 " id="ExtraGap">
                      <div className="text-white text-2xl lg:text-2xl xl:text-3xl">
                        <FontAwesomeIcon icon={faYoutube} />
                      </div>
                      <div className="text-white text-2xl lg:text-2xl xl:text-3xl">
                        <FontAwesomeIcon icon={faFacebook} />
                      </div>
                      <div className="text-white text-2xl lg:text-2xl xl:text-3xl">
                        <FontAwesomeIcon icon={faInstagram} />
                      </div>
                      <div className="text-white text-2xl lg:text-2xl xl:text-3xl">
                        <FontAwesomeIcon icon={faXTwitter} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="footer-bottom mt-20 sm:mt-5 2xl:mt-10 flex justify-between">
                  <div className="">
                    <p className="text-white tetx-2xl">
                      &copy; 2024 to Endula. All rights reserved.
                    </p>
                  </div>
                  <div className="flex items-start ">
                    <p className="text-white text-2xl">Powered By @Novatales</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
