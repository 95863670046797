import React, { useState, useEffect } from "react";
import "../css/Contactus.css";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
import { Helmet } from "react-helmet";
import Clients from "./Clients";
export default function ContactUs() {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    fullname: "",
    phoneNumber: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      try {
        const response = await fetch("/contact-form.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
          body: JSON.stringify(formData),
        });

        const result = await response.json();

        if (result.type === "success") {
          Toastify({
            text: result.message,
            duration: 3000,
            gravity: "top",
            position: "right",
            backgroundColor: "#28a745",
          }).showToast();
        } else {
          Toastify({
            text: result.message,
            duration: 3000,
            gravity: "top",
            position: "right",
            backgroundColor: "#dc3545",
          }).showToast();
        }
      } catch (error) {
        console.error("Error:", error);
        Toastify({
          text: "There was an error. Please try again later.",
          duration: 3000,
          gravity: "top",
          position: "right",
          backgroundColor: "#dc3545",
        }).showToast();
      }
    }
    setValidated(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>Contact Us | Endula Infra - Connect With Our Team</title>
        <meta
          name="keywords"
          content="Endula Infra Contact, Contact Endula Infra, Infrastructure Solutions Contact, Construction Support, Reach Endula Infra, Customer Support Endula Infra"
        />
        <meta
          name="description"
          content="Get in touch with Endula Infra for expert infrastructure and construction solutions. Reach out to our team for inquiries, partnerships, or support today!"
        />
      </Helmet>
      <div className="contactUs-page">
        <section className="section contact-page">
          <Container fluid>
            <div className="Contactus-heading">
              <h2 className="text-[45px]  sm:text-3xl md:text-5xl lg:text-7xl xl:8xl">Contact us</h2>
            </div>
            <Container>
              <Row className="d-flex justify-content-center align-items-center sm:gap-0 gap-3">
                <Col lg={5} sm={10} className="contact-details">
                  <div className="d-flex gap-3 m-5">
                    <FontAwesomeIcon icon={faLocationDot} className="text-xl"  />
                    <h5>JF8M+P86, Medchal, Secunderabad, Telangana 501401</h5>
                  </div>
                  <div className="d-flex gap-3 m-5">
                    <FontAwesomeIcon icon={faPhone}  className="text-xl"/>
                    <h5>+91 9876543210</h5>
                  </div>
                  <div className="d-flex gap-3 m-5">
                    <FontAwesomeIcon icon={faEnvelope}  className="text-xl"/>
                    <h5 className="text-wrap text-lg sm:text-xl">Info@endulaInfra.com</h5>
                  </div>
                </Col>
                <Col lg={1}></Col>
                <Col lg={5} sm={10}>
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <Form.Group controlId="validationCustom01" className="mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Name"
                        name="fullname"
                        value={formData.fullname}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="validationCustom02" className="mb-3">
                      <Form.Label>Mobile Number</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Mobile Number"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group
                      controlId="validationCustomUsername"
                      className="mb-3"
                    >
                      <Form.Label>Email</Form.Label>
                      <InputGroup hasValidation>
                        <i className = "input-group-text" id="inputGroupPrepend">
                          @
                        </i>
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          aria-describedby="inputGroupPrepend"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a valid email.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-5">
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Leave a comment here"
                        name="message"
                        style={{ height: "100px" }}
                        value={formData.otherInfo}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Button type="submit" className="submit-button">
                      Submit form
                    </Button>
                  </Form>
                </Col>
              </Row>
            </Container>
          </Container>
        </section>
      </div>
      <Clients />
      <div className="map">
        <section className="map-sec mapsection">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d121686.54216483909!2d78.40084944297146!3d17.6167643077585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3bcb8753e0295d29%3A0x7d5bc0f5c7da44dd!2sJF8M%2BP86%2C%20Medchal%2C%20Secunderabad%2C%20Telangana%20501401!3m2!1d17.6167497!2d78.48324649999999!5e0!3m2!1sen!2sin!4v1736241154701!5m2!1sen!2sin"
            width="100%"
            height="650"
            style={{ border: "0" }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="iframe"
          />
        </section>
      </div>
      <div className="gap-3">
        {/* <section data-w-id="4ec5de62-1df9-cda3-5737-2bd1649530dc" className="section-spacer fac-pic"></section> */}
      </div>
    </div>
  );
}
