import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Carousel } from 'react-bootstrap';
import AGSLogo from '../assets/Images/AGS-JB-Properties-Logo.jpg';
import AparnaLogo from '../assets/Images/Aparna-Logo.png';
import APRLogo from '../assets/Images/APR-Group-Logo.jpg';
import ARKLogo from '../assets/Images/ARK-Group-Logo.png';
import GLOBALLogo from '../assets/Images/global-mineral-works-Logo.png';
import GOWRAVENTURESLogo from '../assets/Images/gowra-logo-retina-Logo.png';
import JAINCONSTRUCTIONLogo from '../assets/Images/jain-constructions-logo.webp';
import NCLLogo from '../assets/Images/NCl-Logo.svg';
import NUVOCOLogo from '../assets/Images/Nuvoco-Logo.png';
import OMSREELogo from '../assets/Images/OmSree-Logo.png';
import RAJAPUSHPALogo from '../assets/Images/Rajapushpa.png';
import SHAPOORJILogo from '../assets/Images/shapoorji-Logo.svg';
import SREEMAN from '../assets/Images/sreemanlogo.png';
import SRIAS from '../assets/Images/Srias-Life-Logo.png';
import '../css/Clients.css';
const clients = [
    { id: 1, name: "AGS & JB PROPERTIES", logo: AGSLogo },
    { id: 2, name: "APARNA CONSTRUCTIONS AND ESTATES PVT LTD", logo: AparnaLogo },
    { id: 3, name: "APR BUILDERS AND CONTRACTORS", logo: APRLogo },
    { id: 4, name: "ARK BUILDERS", logo: ARKLogo },
    { id: 5, name: "GLOBAL MINERAL WORKS", logo: GLOBALLogo },
    { id: 6, name: "GOWRA VENTURES PRIVATE LIMITED", logo: GOWRAVENTURESLogo },
    { id: 7, name: "JAIN CONSTRUCTIONS", logo: JAINCONSTRUCTIONLogo },
    { id: 8, name: "NUVOCO VISTAS CORPORATION LTD.", logo: NUVOCOLogo },
    { id: 9, name: "OM SREE BUILDERS & DEVELOPERS", logo: OMSREELogo },
    { id: 10, name: "NCL INDUSTRIES LIMITED", logo: NCLLogo },
    { id: 11, name: "SHAPOORJI PALLONJI AND COMPANY PRIVATE LIMITED", logo: SHAPOORJILogo },
    { id: 12, name: "SREEMAN CONSTRUCTIONS", logo: SREEMAN },
    { id: 13, name: "RAJA PUSHPA PROPERTIES PRIVATE LIMITED", logo: RAJAPUSHPALogo },
    { id: 14, name: "SRIAS LIFE SPACES LLP", logo: SRIAS },
    { id: 15, name: "G R INFRACON PRIVATE LIMITED", logo: "https://via.placeholder.com/150" },
    { id: 16, name: "GOWRA ESTATES PRIVATE LIMITED", logo: "https://via.placeholder.com/150" },
    { id: 17, name: "GOWRA PALLADIUM PRIVATE LIMITED", logo: "https://via.placeholder.com/150" },
    { id: 18, name: "HELLA INFRA MARKET PRIVATE LIMITED", logo: "https://via.placeholder.com/150" },
    { id: 19, name: "INVENTAA INDUSTRIES PRIVATE LIMITED", logo: "https://via.placeholder.com/150" },
    { id: 20, name: "JAIDHAR CONSTRUCTIONS", logo: "https://via.placeholder.com/150" },
    { id: 21, name: "K M R ESTATES & BUILDERS PRIVATE LIMITED", logo: "https://via.placeholder.com/150" },
    { id: 22, name: "KALPATARU POWER TRANSMISSION LTD", logo: "https://via.placeholder.com/150" },
    { id: 23, name: "MY HOME INFRASTRUCTURES PVT LTD.", logo: "https://via.placeholder.com/150" },
    { id: 24, name: "NJR CONSTRUCTIONS PRIVATE LIMITED.", logo: "https://via.placeholder.com/150" },
    { id: 25, name: "QUBEX SILICON INDUSTRIES", logo: "https://via.placeholder.com/150" },
    { id: 26, name: "RAJAPUSHPA INFRA PRIVATE LIMITED", logo: "https://via.placeholder.com/150" },
    { id: 27, name: "SRI LAKSHMI ENTERPRISES", logo: "https://via.placeholder.com/150" },
    { id: 28, name: "TELLAPUR TECHNOCITY PRIVATE LIMITED", logo: "https://via.placeholder.com/150" },
    { id: 29, name: "VEDANTA CONSTRUCTIONS", logo: "https://via.placeholder.com/150" }
];

// Function to chunk clients into rows of 3
const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
    }
    return result;
};

const Clients = () => {
    const [chunkSize, setChunkSize] = useState(3); // Default chunk size

    // Update chunk size based on screen width
    useEffect(() => {
        const updateChunkSize = () => {
            if (window.innerWidth < 768) {
                setChunkSize(1); // Mobile screens
            } else {
                setChunkSize(3); // Larger screens
            }
        };

        updateChunkSize(); // Set initial chunk size
        window.addEventListener('resize', updateChunkSize); // Listen for resize events

        return () => window.removeEventListener('resize', updateChunkSize); // Cleanup on unmount
    }, []);

    const clientsWithLogos = clients.filter(client => client.logo);
    const clientChunks = chunkArray(clientsWithLogos, chunkSize);


    return (
        <div className="clients" id="OurClients">
        <div className="client-section">
            <Container className="py-5">
                <h2 className="text-center text-[white] mb-20 text-5xl">Our Clients</h2>
                <Carousel interval={null}>
                    {clientChunks.map((chunk, index) => (
                        <Carousel.Item key={index}>
                            <Row className="g-4 gy-4">
                                {chunk.map(client => (
                                    <Col key={client.id} xs={12} md={4} lg={4}>
                                        <Card className="text-center shadow-sm border-0 h-100 d-flex flex-column">
                                            <div
                                                className="d-flex align-items-center justify-content-center"
                                                style={{
                                                    height: "150px",
                                                    overflow: "hidden",
                                                    backgroundColor: "#e6e9f3",
                                                    paddingTop: "60px",
                                                }}
                                            >
                                                <Card.Img
                                                    variant="top"
                                                    src={client.logo}
                                                    style={{
                                                        objectFit: "contain",
                                                        maxHeight: "100%",
                                                        maxWidth: "80%",
                                                        marginBottom: '60px',
                                                    }}
                                                />
                                            </div>
                                            <Card.Body className="d-flex align-items-center justify-content-center">
                                                <Card.Title className="text-muted text-center">
                                                    {client.name}
                                                </Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Container>
        </div>
    </div>
    );
};

export default Clients;
