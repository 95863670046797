import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightLong,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import AggregatesImage from "../assets/Images/AGGREGATES 00.1.jpg";
import CrystalSamndImage from "../assets/Images/CRYSTAL SAND 00.3.jpg";
import RMCImage from "../assets/Images/READY MIX CONCRETE.jpg";
const Carousel = () => {
  const carouselRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [visible, setVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const move = (direction) => {
    if (buttonDisabled) return;
    setButtonDisabled(true);

    setTimeout(() => setButtonDisabled(false), 800);

    setCurrentIndex((prevIndex) =>
      direction === "next" ? (prevIndex + 1) % 3 : (prevIndex - 1 + 3) % 3
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700); // Adjust the breakpoint as needed
    };

    handleResize(); // Check on initial render
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const translateValue = -currentIndex * `${isMobile ? '100' : '80'} `; // Change to 80 for active item to take up 80%
    carouselRef.current.style.transform = `translateX(${translateValue}%)`;
  }, [currentIndex]);

  const handleMouseMove = (event) => {
    const rect = carouselRef.current.getBoundingClientRect();
    const cursorDiameter = 8 * 16; // 8rem in pixels, adjust if your root font size changes

    setPosition({
      x: event.clientX - rect.left - cursorDiameter / 2, // Center cursor
      y: event.clientY - rect.top - cursorDiameter / 2, // Center cursor
    });
  };

  const handleMouseEnter = () => {
    setVisible(true);
  };

  const handleMouseLeave = () => {
    setVisible(false);
  };
 
  return (
    <div className="carousel__wrapper">
      <div className="carousel__nav">
        <div
          className="carousel__nav__item"
          onClick={() => move("prev")}
          disabled={buttonDisabled}
        >
          <FontAwesomeIcon icon={faChevronLeft} size="lg" />
        </div>
        <div
          className="carousel__nav__item"
          onClick={() => move("next")}
          disabled={buttonDisabled}
        >
          <FontAwesomeIcon icon={faChevronRight} size="lg" />
        </div>
      </div>
      <div className="carousel" ref={carouselRef}>
        <div
          className="carousel__item "
          onMouseMove={handleMouseMove}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Link to="/Ready-Mix-Concrete">
            <img
              src={RMCImage}
              alt="Ready-Mix-Concrete"
              className="inner-img"
              style={{
                width: isMobile ? '90%' : '100%',
            }}
            />
            <div className="carousel__text">
              <h3 className="text-md lg:text-3xl xl:text-5xl">Ready Mix Concrete</h3>
              <p className=" text-xs lg:text-2xl xl:text-4xl" >
                We provide a wide range of mix-designs ranging from M10 to M100
                which is a feat that cannot be replicated by anyone in the city.
              </p>
            </div>
            <div className="carousel__Arrow" style={{
              right: isMobile ? '80px' : '20px',
              bottom:isMobile ? '130px' : '119px'
            }}>
              <FontAwesomeIcon icon={faArrowRightLong} />
            </div>
          </Link>
        </div>
        <div
          className="carousel__item"
          onMouseMove={handleMouseMove}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Link to="/Crystal-Sand">
            <img
              src={CrystalSamndImage}
              alt="Crystal-Sand"
              className="inner-img"
              style={{
                width: isMobile ? '90%' : '100%',
            }}
            />
            <div className="carousel__text">
              <h3 className="text-md lg:text-3xl xl:text-5xl">Crystal Sand</h3>
              <p className=" text-xs lg:text-2xl xl:text-4xl">
                This is the future of sand as we know it. Welcome to the Future.
              </p>
            </div>
            <div className="carousel__Arrow" id="" style={{
              right: isMobile ? '80px' : '20px',
              bottom:isMobile ? '130px' : '119px'
            }}>
              <FontAwesomeIcon icon={faArrowRightLong} />
            </div>
          </Link>
        </div>
        <div
          className="carousel__item"
          onMouseMove={handleMouseMove}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Link to="/Aggregates">
            <img src={AggregatesImage} alt="Aggregates" className="inner-img"  style={{
                width: isMobile ? '90%' : '100%',
            }} />
            <div className="carousel__text">
              <h3 className=" text-md lg:text-3xl xl:text-5xl">Aggregates</h3>
              <p className="text-xs lg:text-2xl xl:text-4xl">
                Aggregates are the materials used in construction and civil
                engineering. They include sand, gravel, crushed stone, and
                recycled concrete.
              </p>
            </div>
            <div className="carousel__Arrow"  style={{
              right: isMobile ? '80px' : '20px',
              bottom:isMobile ? '130px' : '119px'
            }}>
              <FontAwesomeIcon icon={faArrowRightLong} />
            </div>
          </Link>
        </div>
        {visible && (
          <div
            className="cursor-wrapper"
            style={{
              position: "absolute",
              left: `${position.x}px`,
              top: `${position.y}px`,
              pointerEvents: "none", // Keeps it non-interactive
            }}
          >
            <div className="cursor-circle">
              <div className="cursor-text">Discover</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Carousel;
