import React from "react";
import RMCBannerVideo from "../assets/Videos/READY MIX CONCRETE 5.71 MB).mp4";
import "../css/RMC.css";
import "../css/MediaQueries/MediaQueryRMC.css";
import { Container } from "react-bootstrap";
import { Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import RmCvIdEo from "../../src/assets/Videos/rmcnew.mp4"
export default function RMCPage() {
  return (
    <div className="Rmc">
      <Helmet>
        <title>
          Ready Mix Concrete manufacturer and Supplier | Endula Infra
        </title>
        <meta
          name="keywords"
          content="ready mix supplier,ready mix concrete manufacturer,ready mix concrete suppliers,rmc readymix concrete,rmc suppliers."
        />
        <meta
          name="description"
          content="Endula complements your construction vision by manufacturing and supplying high quality, customized, reliable and suatainable ready mix concrete in all grades."
        />
      </Helmet>
      <div className="fac-hero">
        <div className="heading-wrap fac">
          <h1
            className="hero-heading"
            style={{ opacity: 1 }}
            words-slide-up=""
            text-split=""
          >
            <span
              className="word"
              style={{
                display: "inline-block",
                transform: "translate(0px, 0px)",
                opacity: 1,
              }}
            >
              <span className="char" style={{ display: "inline-block" }}>
                F
              </span>
              <span className="char">o</span>
              <span className="char">r</span>
              <span className="char">g</span>
              <span className="char">i</span>
              <span className="char">n</span>
              <span className="char">g</span>
              <span>&nbsp;</span>
              <span className="char">d</span>
              <span className="char">u</span>
              <span className="char">r</span>
              <span className="char">a</span>
              <span className="char">b</span>
              <span className="char">i</span>
              <span className="char">l</span>
              <span className="char">i</span>
              <span className="char">t</span>
              <span className="char">y</span>
            </span>
            <br />
            <span
              className="word"
              style={{
                display: "inline-block",
                transform: "translate(0px, 0px)",
                opacity: 1,
              }}
            >
              <span className="char" style={{ display: "inline-block" }}>
                s
              </span>
              <span className="char">h</span>
              <span className="char">a</span>
              <span className="char">p</span>
              <span className="char">i</span>
              <span className="char">n</span>
              <span className="char">g</span>
              <span>&nbsp;</span>
              <span className="char">p</span>
              <span className="char">o</span>
              <span className="char">s</span>
              <span className="char">s</span>
              <span className="char">i</span>
              <span className="char">b</span>
              <span className="char">i</span>
              <span className="char">l</span>
              <span className="char">i</span>
              <span className="char">t</span>
              <span className="char">y</span>
              <span className="char">.</span>
            </span>
          </h1>
        </div>
        <div
          className="arrows-trigger fac"
          style={{
            transform:
              "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d",
          }}
        ></div>
        <div className="background-noise-new"></div>
        <div className="hero-gradient"></div>
        <div className="video-fac w-background-video w-background-video-atom">
          <video autoPlay loop muted playsInline>
            <source src={RMCBannerVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="section-anim-trigger"></div>
      </div>
      <section className="section-spacer fac-video"></section>
      <div className="section-wrap campus">
        <section className="section campus">
          <div className="section-cont campus">
            <div className="campus-heading">
              RMC is a concrete manufactured in a batching plant for supply to
              sites in freshly mixed or unhardened state, to be mixed with water
              and compacted to form concrete. It is used extensively in
              construction industry since it has many advantages over on-site
              method of concrete production.
            </div>
            <div className="RMC-img-wrap">
              <div className="concrete-image"></div>
            </div>
          </div>
        </section>
      </div>
      <section className="section-spacer campus"></section>
      <div className="section-wrap email">
        <section className="section email about-section">
          <div className="section-cont email d-flex justify-center align-items-center">
            <div className="email-lottie text-center">
              <video autoPlay loop muted playsInline>
                <source src={RmCvIdEo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="rmc-content">
              <p>
                We provide a wide range of mix-designs ranging from M10 to M100
                which is a feat that cannot be replicated by anyone in the city.
              </p>
              <p>
                Our Ready Mix Concrete ranges from M10 to M100 each suitable for
                various construction needs
              </p>
              <ul>
                <li>
                  M10 : Ideal for simple constructions like pathways and
                  non-load bearing walls.
                </li>
                <li>
                  M15 – M20: Ideal for Residential and commercial building
                  Foundations
                </li>
                <li>
                  M25 – M35: Ideal for Concrete structures such as slabs, beams
                  and columns.
                </li>
                <li>
                  M40 – M50: Ideal for Heavy duty infrastructure projects like
                  highways and bridges.
                </li>
                <li>
                  M100: Ideal for high strength pre stressed beams, High-rise
                  buildings, Marine and Offshore structures.
                </li>
              </ul>
            </div>
          </div>
          <div className="section-anim-trigger"></div>
        </section>
      </div>
      <div className="section-spacer email"></div>
      <div className="section-wrap programs ">
        <section className="section program d-flex justify-content-center align-items-center">
          <div className="section-cont program ">
            <Container fluid>
              <div className="benefits-heading">
                <h2>Benefits of Ready Mix Concrete</h2>
              </div>
              <Table bordered responsive>
                <thead className="text-center">
                  <tr>
                    <th>Ready Mix Concrete (RMC)</th>
                    <th>Site Mixed Concrete</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      RMC has uniform quality and consistency due to precise mix
                      proportions and automated process.
                    </td>
                    <td>
                      Quality and consistency is dependent on manual mixing and
                      on-site conditions.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      RMC has a faster construction process as concrete is
                      delivered in a ready-to-use state.
                    </td>
                    <td>
                      Site mixed concrete is a time-consuming process as the
                      mixing is done on-site.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      RMC requires fewer site workers, reducing labor costs.
                    </td>
                    <td>
                      Requires more labor for mixing the concrete materials.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      There will be minimal wastage as the materials are
                      precisely measured and mixed.
                    </td>
                    <td>
                      There will be higher wastage due to onsite handling and
                      inaccurate mixing.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      RMC requires no space storage for the raw materials.
                    </td>
                    <td>
                      Requires storage space for raw materials like cement,
                      sand, etc.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Environmental impact is lower due to reduced wastage and
                      optimized use of materials.
                    </td>
                    <td>
                      Impact on the environment is higher due to dust generation
                      on the construction site.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Delivered directly to the site, reducing logistical
                      challenges.
                    </td>
                    <td>Raw materials need to be transported separately.</td>
                  </tr>
                  <tr>
                    <td>
                      Curing starts immediately after pouring, ensuring better
                      strength and durability.
                    </td>
                    <td>
                      Curing is delayed due to onsite activities affecting the
                      strength.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      RMC mix can be customized as per the project requirements.
                    </td>
                    <td>Customization is possible but with less precision.</td>
                  </tr>
                </tbody>
              </Table>
            </Container>
            <div className="parallax-anim-trigger"></div>
          </div>
        </section>
      </div>
      <section className="section-spacer programs"></section>
      <div className="section-wrap people">
        <section className="section people">
          <div className="section-cont people">
            <div
              className="people-heading people text-center"
              style={{
                transform:
                  "translate3d(0px, 0vw, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d",
                opacity: 1,
              }}
            >
              We boast a state-of-the-art lab to check quality control with no
              margin of error
            </div>
            <div
              className="people-section-descr science people"
              style={{
                transform:
                  "translate3d(0px, 0vw, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d",
                opacity: 1,
              }}
            >
              To accompany those interested in making an informed choice of
              study path, we offer individuals and schools the opportunity to
              get to know the university by participating in orientation people,
              such as the Open Day.
            </div>
          </div>
        </section>
      </div>
      <section className="section-spacer people"></section>
    </div>
  );
}
