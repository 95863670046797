import React, { useState } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import '../css/home.css';
import ParticleSize from'../assets/Images/sieve analysis Testcard.jpg';
import Density from'../assets/Images/Density TestsCard .jpg';
import agreegatesimpact from'../assets/Images/impactTestCard.jpg';
import agreegatescrush from'../assets/Images/crushingTestCard.jpg';
import Flakiness from'../assets/Images/flakiness index & elongation index-minCard.jpg';
import waterAbsorption from'../assets/Images/water absorption test-minCard.jpg';
const ButtonCardDisplay = () => {
  const [activeButton, setActiveButton] = useState(1);
  const [fadeClass, setFadeClass] = useState(false);

  const buttonData = [
    {
      id: 1,
      label: "/ Particle Size and Density Analysis",
      cards: [
        { imageURL: ParticleSize, content: "We regularly undergo sieve analysis test on Artificial Sand, Aggregates 150mm, and 40mm to determine the accurate particle size. Samples are collected from the conveyor belts, stockpiles and loaded trucks for the intended analysis." },
        { imageURL:Density, content: "This test helps us to know the compactness and density of aggregates which is essential for structural concrete." },
      ],
    },
    {
      id: 2,
      label: "/ Impact and Crushing Strength Analysis",
      cards: [
        { imageURL: agreegatesimpact, content: "This test helps to determine how strong aggregates are for sudden impacts and gives an understanding of how strong the material is." },
        { imageURL: agreegatescrush, content: "The strength to bear load and stress on the aggregate is determined through its crushing strength." },
      ],
    },
    {
      id: 3,
      label: "/ Aggregate Geometry and Porosity Analysis",
      cards: [
        { imageURL: Flakiness, content: "These tests help to confirm that our aggregates complies with prescribed standards concerning the shape and size of these particles vital for the best performance in construction." },
        { imageURL: waterAbsorption, content: "Porosity and moisture absorption test are inevitable before one can pass any verdict on the ability of the materials to absorb water." },
      ],
    },
  ];

  const handleButtonClick = (id) => {
    if (id !== activeButton) {
      setFadeClass(false); // Reset animation
      setActiveButton(id);
      setTimeout(() => setFadeClass(true), 100); // Trigger animation
    }
  };


  return (
    <div className="HomeQuality">
      <div className="quality-section-heading">
        <h2 className="text-5xl md:text-8xl   sm:mt-[-6.5rem] mb-2 text-white" id="QualityTxt">Quality</h2>
      </div>
      <Container>
        <div className=" flex flex-col sm:justify-between sm:flex-row mb-4  text-changing-buttons">
          {buttonData.map((button) => (
            <button
              key={button.id}
              className={`mx-2 text-sm sm:text-md lg:text-lg xl:text-xl  ${activeButton === button.id ? "active" : ""}`}
              onClick={() => handleButtonClick(button.id)}
            >
              {button.label}
            </button>
          ))}
        </div>

        <Row className="mt-4 homecard">
          {buttonData
            .filter((button) => button.id === activeButton)
            .flatMap((button) => button.cards)
            .map((card, index) => (
              <Col key={index} lg={6} md={6} className="mb-4">
                <Card
                  className={`custom-card ${fadeClass ? "fade-up" : ""}`}
                  style={{ transition: "opacity 0.5s ease" }}
                >
                  <Card.Img variant="top" src={card.imageURL} alt="Card image" />
                  <Card.Body>
                    <Card.Text className="xl:text-2xl 2xl:text-3xl" >{card.content}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
        </Row>
      </Container></div>
  );
};

export default ButtonCardDisplay;
