import React from 'react'
import '../css/QualityAndProcess.css';
import '../css/MediaQueries/MediaQueryQuality.css';
import QualityBanner from '../assets/Videos/quality PROCESS (5.9 MB).mp4'
import Lottie from 'lottie-react'
import Process from '../Lottiefiles/CRUSHER 9.json'
import SieveIcon from '../assets/Images/Sieve Analysis.png'
import DLBDIcon from '../assets/Images/Density Tests.png'
import AggregatesImpactIcon from '../assets/Images/Aggregate Impact Value Test.png'
import AggregatesCrushingIcon from '../assets/Images/Aggregate Crushing Value Test.png'
import FlakinessIcon from '../assets/Images/Flakiness Index & Elongation Index Test.png'
import WaterAbosrptionIcon from '../assets/Images/Water Absorption Test.png'
import SpecificGravityIcon from '../assets/Images/Specific Gravity Test.png'
import { Helmet } from 'react-helmet'
export default function QualityAndProcess () {
  return (
    <div className='QualityPage'>
      <Helmet>
        <title>Aggregates production and manufacturing process | Endula</title>
        <meta
          name='keywords'
          content='aggregate manufacturing process,aggregate processing,aggregate quality control,aggregate production plant.'
        />
        <meta
          name='description'
          content='During the process of manufacturing aggregates the raw material is crushed and refined down to the most common crystal sand and gravel for construction use.'
        />
      </Helmet>
      <div className='fac-hero'>
        <div className='heading-wrap fac'>
          <h1
            className='hero-heading'
            style={{ opacity: 1 }}
            words-slide-up=''
            text-split=''
          >
            Quality in Every Mix
          </h1>
        </div>
        <div
          className='arrows-trigger fac'
          style={{
            transform:
              'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
            transformStyle: 'preserve-3d'
          }}
        ></div>
        <div className='background-noise-new'></div>
        <div className='hero-gradient'></div>
        <div className='video-fac w-background-video w-background-video-atom'>
          <video autoPlay loop muted playsInline>
            <source src={QualityBanner} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className='section-anim-trigger'></div>
      </div>
      <section className='section-spacer fac-video'></section>
      <div className='section-wrap email'>
        <section className='section email fac3'>
          <div className='section-cont email fac3 '>
            <div className='email-lottie fac3'>
              <Lottie
                animationData={Process}
                loop={true}
                width='100%'
                height='100%'
              />
            </div>
            <div className='email-text-wrap'>
              <div
                style={{
                  transform:
                    'translate3d(0px, 0vw, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  transformStyle: 'preserve-3d',
                  opacity: 1
                }}
                className='email-heading fac3'
              >
                Endula infra plant consists of three stages where crushing takes
                place – primary,secondary and tertiary stage.
              </div>
              <div
                data-w-id='cb010bdd-2817-14a8-ded0-2e219e70f18c'
                style={{
                  transform:
                    'translate3d(0px, 0vw, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                  transformStyle: 'preserve-3d',
                  opacity: 1
                }}
                className='email-sm-text-wrap'
              >
                <div className='text-20px email white'>
                  Granite rock is brought from quarries after drilling,
                  blasting, segregation and transportation to the plant is fed
                  in to the primary stage consisting of one jaw crusher that
                  sizes down the granite to produce 150mm aggregate. This is
                  then fed in to the secondary stage consisting of one jaw
                  crusher/ one cone crusher based on requirements to produce
                  40mm size aggregate .
                </div>
                <div className='text-20px email last white'>
                  Which in turn is fed in to the tertiary stage consisting one
                  vertical shaft impactor(VSI) with latest DFP technology that
                  produces completely dust free sand and aggregate of the
                  required gradation and shape. The plant works on the principle
                  of continuous feed in closed stages and adopts techniques
                  synonymous with nature’s process on river sand.
                </div>
              </div>
            </div>
          </div>
          <div className='section-anim-trigger'></div>
        </section>
      </div>
      <div className='section-spacer email'></div>
      <section className='section-wrap science'>
        <div className='section science'>
          <div className='section-cont science'>
            <div className='science-left'>
              <div className='science-sticky'>
                <h2
                  style={{
                    transform:
                      'translate3d(0vw, 0vw, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                    opacity: 1,
                    transformStyle: 'preserve-3d'
                  }}
                  className='h3-heading science'
                >
                  Quality
                </h2>
                <div
                  style={{
                    transform:
                      'translate3d(0px, 0vw, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                    opacity: 1,
                    transformStyle: 'preserve-3d'
                  }}
                  className='section-descr science'
                >
                  Endula Infra insists on quality tests after the production of
                  every product to ensure that they meet the highest standards
                  of performance, safety and reliability.
                </div>
              </div>
            </div>
            <div
              style={{
                transform:
                  'translate3d(0vw, 0vw, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
                opacity: 1,
                transformStyle: 'preserve-3d'
              }}
              className='science-right'
            >
              <div className='science-block'>
                <img src={SieveIcon} alt='Sieve Analysis Test' />
                <div className='text-60px science-block-head'>
                  Sieve Analysis Test
                </div>
                <div className='text-20px science-block-text'>
                  We regularly undergo sieve analysis test on Artificial Sand,
                  Aggregates 150mm, and 40mm to determine the accurate particle
                  size. Samples are collected from the conveyor belts,
                  stockpiles and loaded trucks for the intended analysis.
                </div>
                <div className='science-divider'></div>
              </div>
              <div className='science-block'>
                <img src={DLBDIcon} alt='DLBD & DRBD Test (Density Tests)' />
                <div className='text-60px science-block-head'>
                  DLBD & DRBD Test (Density Tests)
                </div>
                <div className='text-20px science-block-text'>
                  This test helps us to know the compactness and density of
                  aggregates which is essential for structural concrete.
                </div>
                <div className='science-divider'></div>
              </div>
              <div className='science-block'>
                <img
                  src={AggregatesImpactIcon}
                  alt='Aggregate Impact Value Test'
                />
                <div className='text-60px science-block-head'>
                  Aggregate Impact Value Test
                </div>
                <div className='text-20px science-block-text'>
                  This test helps to determine how strong aggregates are for
                  sudden impacts and gives an understanding of how strong the
                  material is.
                </div>
                <div className='science-divider'></div>
              </div>
              <div className='science-block'>
                <img
                  src={AggregatesCrushingIcon}
                  alt='Aggregate Crushing Value Test'
                />
                <div className='text-60px science-block-head'>
                  Aggregate Crushing Value Test
                </div>
                <div className='text-20px science-block-text'>
                  The strength to bear load and stress on the aggregate is
                  determined through its crushing strength.
                </div>
                <div className='science-divider'></div>
              </div>
              <div className='science-block'>
                <img
                  src={FlakinessIcon}
                  alt='Flakiness Index & Elongation Index Test'
                />
                <div className='text-60px science-block-head'>
                  Flakiness Index & Elongation Index Test
                </div>
                <div className='text-20px science-block-text'>
                  These tests help to confirm that our aggregates complies with
                  prescribed standards concerning the shape and size of these
                  particles vital for the best performance in construction.
                </div>
                <div className='science-divider'></div>
              </div>
              <div className='science-block'>
                <img src={WaterAbosrptionIcon} alt='Water Absorption Test' />
                <div className='text-60px science-block-head'>
                  Water Absorption Test
                </div>
                <div className='text-20px science-block-text'>
                  Porosity and moisture absorption test are inevitable before
                  one can pass any verdict on the ability of the materials to
                  absorb water.
                </div>
                <div className='science-divider'></div>
              </div>
              <div className='science-block last'>
                <img src={SpecificGravityIcon} alt='Specific-gravity-Icon' />
                <div className='text-60px science-block-head'>
                  Specific Gravity Test
                </div>
                <div className='text-20px science-block-text'>
                  Test that is useful in determining the density of aggregates,
                  When performing construction calculations, it is important to
                  determine the specific gravity of the aggregates.
                </div>
                <div className='science-divider'></div>
              </div>
            </div>
            <div className='section-anim-trigger'></div>
          </div>
        </div>
      </section>
      <section className='section-spacer science'></section>
      <div className='section-wrap fac-pic'>
        <section className='section fac-pic'>
          <div
            className='fac-img fac3'
            style={{
              willChange: 'transform',
              transform:
                'translate3d(0px, -4.09935%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
              transformStyle: 'preserve-3d'
            }}
          ></div>
          <div className='section-anim-trigger footer'></div>
        </section>
      </div>
      <section className='section-spacer fac-pic'></section>
    </div>
  )
}
